exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-components-contact-tsx": () => import("./../../../src/pages/components/contact.tsx" /* webpackChunkName: "component---src-pages-components-contact-tsx" */),
  "component---src-pages-components-hero-tsx": () => import("./../../../src/pages/components/hero.tsx" /* webpackChunkName: "component---src-pages-components-hero-tsx" */),
  "component---src-pages-components-info-tsx": () => import("./../../../src/pages/components/info.tsx" /* webpackChunkName: "component---src-pages-components-info-tsx" */),
  "component---src-pages-components-pricing-tsx": () => import("./../../../src/pages/components/pricing.tsx" /* webpackChunkName: "component---src-pages-components-pricing-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

